<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    iban?: string
    cardIcon?: string
  }>(),
  {
    cardIcon: 'edit',
    iban: undefined,
  },
)

const emit = defineEmits<{
  (e: 'click'): void
}>()

const { t } = useI18n()

const paymentIBANOptions = computed(() => {
  return {
    title: t('components.paymentInfo.bankAccount.title'),
    subTitle: props.iban || t('components.paymentInfo.bankAccount.subtitle'),
    icon: props.cardIcon,
  }
})

function handleIbanClick() {
  emit('click')
}
</script>

<template>
  <PaymentInfoContainer
    data-id="payment-iban"
    :heading="$t('components.paymentInfo.bankAccount.heading')"
    :sub-heading="$t('components.paymentInfo.bankAccount.subheading')"
    :payment-card-options="paymentIBANOptions"
    @click="handleIbanClick"
  />
</template>
