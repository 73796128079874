<script setup lang="ts">
import { ProfileResponsePaymentSchedule } from '~/schema'

const props = withDefaults(
  defineProps<{
    paymentScheduleType: string
    iban?: string
    subTitle?: string
    cardIcon?: string
    disabled?: boolean
  }>(),
  {
    iban: '',
    subTitle: '',
    cardIcon: 'edit',
    disabled: false,
  },
)

defineEmits<{
  (e: 'click'): void
}>()

const { t } = useI18n()

const translations = computed(() => {
  switch (props.paymentScheduleType) {
    case ProfileResponsePaymentSchedule.Quarterly:
      return [
        t('components.paymentInfo.paymentSchedule.quarterly.title'),
        t('components.paymentInfo.paymentSchedule.quarterly.subtitle'),
      ]
    case ProfileResponsePaymentSchedule.Monthly:
      return [
        t('components.paymentInfo.paymentSchedule.monthly.title'),
        t('components.paymentInfo.paymentSchedule.monthly.subtitle'),
      ]
    case ProfileResponsePaymentSchedule.HalfYear:
      return [
        t('components.paymentInfo.paymentSchedule.half.yearly.title'),
        t('components.paymentInfo.paymentSchedule.half.yearly.subtitle'),
      ]
    case ProfileResponsePaymentSchedule.OnRequest:
      return [
        t('components.paymentInfo.paymentSchedule.demand.title'),
        t('components.paymentInfo.paymentSchedule.demand.subtitle'),
      ]
    case ProfileResponsePaymentSchedule.Immediately:
      return [t('components.paymentInfo.paymentSchedule.immediately.title')]
    case ProfileResponsePaymentSchedule.UtoringPayment:
      return [t('components.paymentInfo.paymentSchedule.utoringPayment.title')]
    case ProfileResponsePaymentSchedule.CreditCard:
      return [t('components.paymentInfo.paymentSchedule.creditCard.title')]
    case ProfileResponsePaymentSchedule.PartnerSummerLACO:
      return [t('components.paymentInfo.paymentSchedule.partnerSummer.title')]
    case ProfileResponsePaymentSchedule.PartnerWinterLACO:
      return [t('components.paymentInfo.paymentSchedule.partnerWinter.title')]
    case ProfileResponsePaymentSchedule.TwiceAMonth:
      return [t('components.paymentInfo.paymentSchedule.twiceAMonth.title')]
    case ProfileResponsePaymentSchedule.TwoWeeksBeforeArrival:
      return [
        t('components.paymentInfo.paymentSchedule.twoWeeksBeforeArrival.title'),
      ]
    case ProfileResponsePaymentSchedule.FourWeeksBeforeArrival:
      return [
        t(
          'components.paymentInfo.paymentSchedule.fourWeeksBeforeArrival.title',
        ),
      ]
    default:
      return [t('components.paymentInfo.paymentSchedule.endOfMonth.title')]
  }
})

const paymentScheduleOptions = computed(() => {
  const [title, subTitle] = translations.value
  return {
    title,
    subTitle: props.subTitle || subTitle,
    disabled: !props.iban || props.disabled,
    icon: props.cardIcon,
  }
})
</script>

<template>
  <PaymentInfoContainer
    data-id="payment-schedule"
    :heading="$t('components.paymentInfo.paymentSchedule.heading')"
    :sub-heading="$t('components.paymentInfo.paymentSchedule.subheading')"
    :payment-card-options="paymentScheduleOptions"
    @click="$emit('click')"
  >
    <template v-for="(index, name) in $slots" #[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </PaymentInfoContainer>
</template>
